import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { ContextPopUps } from 'context';
import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { popUps } from 'components/constants/constants';
import { GET_TRANSACTIONS } from 'graphQl/query/transactions/transactions';

import BonusMarketing from 'components/Pages/BoonusPage/components/BonusMarketing';

import { DEPOSIT_TRANSACTION, NEW_USER_BONUS } from './constants';

const MarketingLayer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [displayBonus, setDisplayBonus] = useState(false);

  const token = useAppSelector(userToken);
  const newUser = localStorage.getItem(NEW_USER_BONUS);

  const { data } = useQuery(GET_TRANSACTIONS, {
    fetchPolicy: 'no-cache',
    skip: !token || Boolean(newUser),
    variables: DEPOSIT_TRANSACTION,
  });

  useEffect(() => {
    if (data) {
      const deposit = pathOr(undefined, ['paginatedTransactions', 'count'], data);

      if (!deposit) {
        setDisplayBonus(true);
      }
    }
  }, [data]);

  const handleDeposit = () => {
    if (token) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: { config: popUps.deposit, active: popUps.deposit },
      });
      localStorage.setItem(NEW_USER_BONUS, 'true');
      setDisplayBonus(false);
      return;
    }

    setPopUpsOpen(popUps.registration);
  };

  return (
    <>
      {children}
      {!token || displayBonus ? <BonusMarketing token={token} onDeposit={handleDeposit} /> : null}
    </>
  );
};

export default MarketingLayer;
