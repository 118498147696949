import { ETokenCode } from 'components/constants/constants';

export type SvgIcon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string;
  }
>;

export enum SocketActions {
  joinGame = 'games:join',
  leaveGame = 'games:leave',
  diceRoll = 'games:dice:roll',
  diceHash = 'games:dice:hash',
  userNotifications = 'subscribe:user',
  balance = 'balance',
  bonusBalance = 'bonus.balance',
  userNotification = 'userNotification',
  rank = 'rank',
  lottery = 'lotteryState',
  exceptions = 'exception',
  connect = 'connect',
  disconnect = 'disconnect',
  coinHash = 'games:coin:hash',
  commitCoinHash = 'games:coin:hash',
  playCoinBet = 'games:coin:bet',
  createCoinRound = 'games:coin:multiply',
  playCoinMultiplyBet = 'games:coin:multiply:bet',
  collectCoinRound = 'games:coin:multiply:collect',
  getCoinRound = 'games:coin:multiply:get',
  rates = 'rates',
  playKenoBet = 'games:keno:bet',
  kenoHash = 'games:keno:hash',
  blackJackInitSession = 'games:blackjack:start',
  blackJackDouble = 'games:blackjack:double',
  blackJackHit = 'games:blackjack:hit',
  blackJackStand = 'games:blackjack:stand',
  blackJackSplit = 'games:blackjack:split',
  blackJackGetSession = 'games:blackjack:get',
  texasHoldemInitSession = 'games:holdem:start',
  texasHoldemFlop = 'games:holdem:flop',
  texasHoldemTurn = 'games:holdem:turn',
  texasHoldemRiver = 'games:holdem:river',
  texasHoldemHash = 'games:holdem:hash',
  texasHoldemCurrentSession = 'games:holdem:get',
  plinkoHash = 'games:plinko:hash',
  plinkoBet = 'games:plinko:bet',
}

export enum UserPreferenceKey {
  promoEmail = 'promoEmail',
  withdrawal = 'withdrawal',
  banned = 'banned',
  language = 'language',
  preferredToken = 'preferredToken',
  enableBet = 'enableBet',
}

export enum AvatarStatus {
  active = 'Active',
  inactive = 'Inactive',
}

export enum InHouseGames {
  dice = 'Dice',
}

export enum DiceBetMode {
  under = 'UNDER',
  over = 'OVER',
}

export enum CoinBetMode {
  heads = 'heads',
  tails = 'tails',
}

export enum MFATypes {
  email = 'Email',
  google = 'Google',
}

export enum GameCategory {
  InHouse = 'In-House',
  LiveCasino = 'Live Casino',
  TableGames = 'Table Games',
  Slots = 'Slots',
}

export enum GameProviders {
  InHouse = 'In-House',
}

export enum MissionRoundStatus {
  active = 'Active',
  completed = 'Completed',
  collected = 'Collected',
}

export enum NotificationType {
  deposit = 'deposit',
  withdraw = 'withdraw',
  tips = 'tips',
  event = 'event',
  rank = 'rank',
  bonus = 'bonus',
  customBonus = 'customBonus',
  lottery = 'lottery',
}

export enum CustomNotificationType {
  customBonusSuccess = 'customBonusSuccess',
  customBonusReject = 'customBonusReject',
}

export enum TransactionStatus {
  complete = 'Complete',
  failed = 'Failed',
  pending = 'Pending',
}

export enum TransactionType {
  any = 'Any',
  deposit = 'Deposit',
  withdrawal = 'Withdrawal',
  dividends = 'Dividends',
  referral = 'Referral',
  bonus = 'Bonus',
  cashback = 'Cashback',
  rankBonus = 'Rank Bonus',
  event = 'Event',
  affiliate = 'Affiliate',
  lottery = 'Lottery',
  tips = 'Tips',
  wheelOfFortune = 'Wheel',
  burn = 'Burn',
}

export enum CardGameStake {
  default = '',
  low = 'low',
  high = 'high',
}

export type TranslationType = (key: string) => string;
export type MissionStars = 'one' | 'two' | 'three';
export type MissionPositions = 1 | 2 | 3 | 4 | 5;

export interface IRank {
  id: number;
  wager: string;
  cashback: string;
  bonus: string;
  avatars: boolean | null;
  tips: boolean | null;
  rakeback: string | null;
  vault: boolean | null;
  vip: boolean | null;
  displayName: string;
}

export interface IAvatar {
  id: string;
  avatar: string;
  rank: number;
  status: AvatarStatus;
  weight: number;
}

export interface IUserPreferences {
  id: string;
  key: UserPreferenceKey | null;
  value: string | null;
}

export interface IAffiliate {
  email: string;
  userId: string;
  percentage: string;
  websites: string[] | null;
}

export interface IUser {
  id: string;
  affiliate?: boolean;
  alias: string;
  email?: string;
  telegramId: string;
  referralCode: string;
  totalWager: string;
  totalMined: string;
  totalPayout: string;
  totalBets: number;
  type: string;
  createdAt: Date;
  rank: IRank;
  avatar?: IAvatar | null;
  preferences: IPreferences;
}

export interface IPreferences {
  promoEmail: boolean;
  newsEmail: boolean;
  accountActivityAlert: boolean;
}

export interface IUserSecurity {
  email_2fa: boolean;
  google_2fa: boolean;
  requireLogin_2fa: boolean;
}

export interface IToken {
  name?: string;
  tokenCode: string;
  displayName: string;
  weight?: number;
}

export interface IUserWallet {
  availableBalance: string;
  token: IToken;
  totalWager: string;
}

export interface IGAmeBetSettings {
  minBet: string;
  maxBet: string;
  maxPayout: string;
}

export interface IGame {
  isBlocked: boolean;
  category: GameCategory;
  gameId: string;
  slug: string;
  id: string;
  image: string;
  currencies: string[];
  isFavorite: boolean;
  name: string;
  new: boolean;
  popular: boolean;
  providerName: string;
  isBonusGame: boolean;
  vip: boolean;
}

export interface IBetResutl {
  outcome: 'WIN' | 'LOSS';
  luckyNumber: number;
}

export interface IBet {
  id: string;
  multiplier: string;
  amount: string;
  payout: string;
  result: IBetResutl;
  tokenCode: ETokenCode;
  displayName: ETokenCode;
  createdAt: string;
  isAuto: boolean;
}

export interface IMission {
  id: string;
  gameCategory: GameCategory | null;
  gameId: string | null;
  mission: string;
  num_bets: number;
  oneStarMinBet: string;
  oneStarMultiplier: string;
  threeStarMinBet: string;
  threeStarMultiplier: string;
  twoStarMinBet: string;
  twoStarMultiplier: string;
  weight: number;
}

export interface IMissionProgress {
  missionId: string;
  oneStarProgress: string;
  threeStarProgress: string;
  twoStarProgress: string;
}

export interface IMissionRound {
  id: string;
  prize: string;
  prizeTokenCode: ETokenCode;
  startAt: string;
  endAt: string;
}

export interface IGameSettings {
  defaultBet: string;
  maximumBet: string;
  maximumPayout: string;
  minimumBet: string;
  token: IToken;
}

export interface IUserNotification {
  content: string;
  createdAt: string;
  id: string;
  isRead: boolean;
  title: string;
  type: NotificationType;
  customType?: CustomNotificationType; // create type and layout to display custom notification
}

export interface IDividend {
  availableBalance: string;
  token: IToken;
}

export interface IWallet {
  availableBalance: string;
  totalWager: string;
  token: IToken;
}

export interface IExchangeRate {
  btcValue: string;
  eurValue: string;
  usdValue: string;
  token: IToken;
  updatedAt: string;
}
export interface INetwork {
  code: string;
  explorer: string;
  name: string;
  standard: string;
}

export interface ITransaction {
  id: string;
  amount: string;
  createdAt: string;
  info: string;
  status: TransactionStatus;
  token: IToken;
  type: TransactionType;
  meta: Record<string, string>;
  network: null | Partial<INetwork>;
  user?: IUser;
}

export type IExchangeRates = Record<string, string>;

export interface ISetting {
  name: string;
  value: string;
}

export interface IRakeback {
  id: string;
  availableBalance: string;
  tokenCode: ETokenCode;
  withdrawalAt: string;
}

export interface ILotteryState {
  id: number;
  prize: string;
  prizeTokenCode: string;
  rewardCode: string;
  ticketPrice: string;
  numberOfTickets: number;
  endAt: string;
  startAt: string;
  displayName: string;
}

export interface HistoryElem {
  multiplier?: string;
  payout?: string;
  createdAt?: Date;
  betId?: string;
  amount?: string;
  isAuto?: boolean;
  tokenCode?: string;
  displayName?: string;
  num?: string;
  result?: string;
  isBlack?: boolean;
  id?: string;
  coefficient?: string;
}

export interface GameType {
  gameId: string;
  src: string;
  onClick: (el: string) => void;
  modeTitle: string;
  name: string;
  category: string;
  slug: string;
  providerName: string;
  title: string;
  isFavorite: boolean;
  freeSpins: boolean;
  popular: boolean;
  new: boolean;
  isBonusGame: boolean;
  drops: boolean;
  providerGameId: string;
  isBlocked: boolean;
}

export interface IBigWin {
  id: string;
  payout: string;
  tokenCode: ETokenCode;
  game: {
    name: string;
  };
  user: {
    alias: string;
    avatar: null | {
      avatar: string;
    };
    rank: {
      id: number;
    };
  };
  createdAt: string;
}

export interface IBetHistory<T = null> {
  id: string;
  amount: string;
  displayName: string;
  tokenCode: ETokenCode;
  isAuto: boolean;
  multiplier: string;
  payout: string;
  result: T;
  createdAt: string;
}

export interface INetworkAddress {
  address: string;
  memo?: string;
}

export interface ISlider {
  id: string;
  deskImage: string;
  tabImage: string;
  mobImage: string;
  url: string;
  status: string;
  weight: number;
  isAuthenticated: boolean;
}

export type UrlType = 'popup' | 'external' | 'internal';
